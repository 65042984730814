import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import title_image from '../images/titles/business_consulting.jpg'
import business_transformation_graphic from '../images/pages/business_transformation_graphic.png'
import person from '../images/person.png'
import ContactText from '../components/ContactText'

const pageTitle = "Business Consulting";
const pageSlug = "business-consulting";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Together we can envision, transform, and inspire your business.</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Many new disruptive forces are impacting companies in a way not seen before. An influx of new competitive 
                    threats is emerging from a variety of unlikely places. New technologies are disrupting old ways of 
                    conducting business and reshaping how work gets done. All the while, Customer expectations are continuing 
                    to rise at an unprecedented rate.
                    </h3>
                    <p>Most groups are struggling to respond and adapt to this fast-paced changing environment, reasons for 
                        this may include:</p>
                    <ul>
                        <li>A lack of understanding of market realities and customer/business insights</li>
                        <li>Poor strategy execution – misalignment of strategy and program execution</li>
                        <li>Legacy cost structures, fixed costs, and infrastructure</li>
                        <li>Lack of analytics and associated data needed to make informed decisions</li>
                        <li>Resistance to change and a culture of complacency</li>
                        <li>Lack of innovation for developing compelling products, operating structures, and services</li>
                        <li>Lack of IT and business agility</li>
                    </ul>
                    <p>Now more than ever, significant pressures are forcing companies to innovate and function at high levels of 
                        performance to consistently stay ahead of the competition.</p>
                    
                    <span className="break"></span>
                    <h6>
                    We always strive to help our clients overcome the complexities of business transformation including:
                    </h6>
                    <ul>
                        <li>Inspiring people and transforming the culture of your business</li>
                        <li>Fast delivery of technology and business solutions</li>
                        <li>Accelerating value realization with project portfolio alignment and a strong strategy</li>
                        <li>Creating and establishing inspiring and compelling visions</li>
                        <li>Comprehending business and customer expectations</li>
                        <li>Designing world-class capabilities that balance technology and business viewpoints</li>
                    </ul>
                    <span className="break"></span>
                    <h4>How We Can Help</h4>
                    <p>Here at Nuerex, our Business Consulting Services are created to address an organization's 
                        end to end business transformation needs.</p>
                    <img src={business_transformation_graphic} />

                </div>
             {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div> */}
            </div>
        </div>
      </section>

      <section className="section static-page page-services">
          <div className="container">
              <div className="page-title-area">
                  <h2 className="title">Business Consulting Services</h2>
                  <h6 className="subtitle">Evolving and transforming your business is something we can do together. Learn more:</h6>
              </div>
              <div className="columns is-multiline">
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/business-strategy"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Business Strategy</h2>
                        <p className="desc">We can help you comprehend your business and customer insights to develop an aligned vision and strategy. 
                        As well as creating a roadmap to guide and transform your efforts.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/operational-and-process-excellence"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Process and Operational Excellence</h2>
                        <p className="desc">Helping you design and deliver first-class capabilities that 
                        drive sustainable and measurable results throughout your business.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/people-and-change-consulting"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">People and Change</h2>
                        <p className="desc">Helping you enable and empower your organization so that people and 
                        culture can truly thrive.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/enterprise-program-management"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Enterprise Program Management</h2>
                        <p className="desc">Together we can create and deliver strategies and initiatives in a 
                        rapid, agile, and value-creating manner.</p>
                      </Link>
                  </div>
              </div>
          </div>
      </section>

     <ContactText />

      </Layout>
    )
  }
}

export default BusinessConsulting
